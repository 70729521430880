<template>
  <div class="remote-streams-medias">
    <b-button v-if="platform === 'desktop' && configs.minimized" variant="link" class="btn-maximize" v-b-tooltip.hover title="Maximize" @click="maximize()">
      <BIconPipFill class="text-white" font-scale="1.45"/>
    </b-button>
    <div @click="onRemoteStreamClick" class="remote-stream">
      <video ref="remoteMedia" :class="remoteMediaClass" autoplay playsInline></video>
      <div class="stream-states">
        <BIconCameraVideoOff class="camera-off" v-if="!remoteMediaState.camera && !remoteMediaState.screenshare"/>
        <BIconMicMute class="mic-off"  v-if="!remoteMediaState.mic"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BIconCameraVideoOff,BIconMicMute,
  BIconPipFill
} from 'bootstrap-vue';

export default {
  props: ['configs','platform'],
  components: {
    BIconCameraVideoOff,BIconMicMute,
    BIconPipFill
  },
  data(){
    return {
      remoteMediaState: {
        camera: false,
        mic: true,
        screenshare: false
      },
      remoteMediaClass: "remote-media video-shadow",
      peerMedia: null,
      activeView: "both"
    };
  },

  methods: {
    maximize(){
      this.$emit('on-action',{event: 'maximize'});
    },

    onRemoteStreamClick(e){
      e.preventDefault();
      if(this.configs.minimized){
        this.maximize();
      }
      else{
        this.handleRemoteScreen();
      }
    },

    handleRemoteScreen(){
      if(this.platform === "desktop" || this.configs.minimized){
        return;
      }
      this.activeView = this.activeView === "both" ? "remoteMedia" : "both"
      this.$emit('on-action',{
        event: 'switchActiveView',
        data: {activeView: this.activeView}
      });
    },


    setRemoteMediaSize(isMinimized){
      let media = this.peerMedia;
      if(!media){
        return false;
      }

      if(this.platform === "desktop" && media.platform.type === "mobile" && ['portrait','landscape'].indexOf(media.platform.orientation) !== -1){
        if(!isMinimized && media.platform.orientation === "portrait"){
          this.remoteMediaClass = 'remote-media-mobile-portrait video-shadow';
        }
        else{
          this.remoteMediaClass = 'remote-media video-shadow';
        }
      }

      if(media.screenshare){
        this.remoteMediaState.screenshare = true;
        this.remoteMediaClass = 'remote-media-screenshare video-shadow';
      }
      else{
        if(this.remoteMediaState.screenshare){
          this.remoteMediaClass = 'remote-media video-shadow';
          this.remoteMediaState.screenshare = false;
        }
      }
    }
  },

  mounted(){
    this.$callstr.setRemoteMedia(this.$refs['remoteMedia']);

    this.$callstr.signal.off('received-peer-media');
    this.$callstr.signal.on('received-peer-media',({media}) => {
      this.remoteMediaState.mic = media.mic;
      this.remoteMediaState.camera = media.camera;
      this.peerMedia = media;
      this.setRemoteMediaSize(this.configs.minimized);
    });
  },

  watch: {
    "configs.minimized": function(isMinimized){
      this.setRemoteMediaSize(isMinimized);
    }
  }
};
</script>
